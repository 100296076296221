<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M452.923,512H59.077C26.449,512,0,485.551,0,452.923V59.077C0,26.449,26.449,0,59.077,0h393.846
	C485.551,0,512,26.449,512,59.077v393.846C512,485.551,485.551,512,452.923,512z"
    />
    <path
      style="fill:#FFC850;"
      d="M512,265.846v-19.692H275.692c-5.438,0-9.846-4.409-9.846-9.846V0h-19.692v236.308
	c0,5.438-4.409,9.846-9.846,9.846H88.615V29.538c0-10.773,2.93-20.842,7.968-29.538H59.077C26.449,0,0,26.449,0,59.077v393.846
	C0,485.551,26.449,512,59.077,512h393.846c21.854,0,40.89-11.898,51.109-29.538H265.846V275.692c0-5.438,4.409-9.846,9.846-9.846
	H512z M246.154,482.462h-98.462c-32.628,0-59.077-26.449-59.077-59.077V265.846h147.692c5.438,0,9.846,4.409,9.846,9.846V482.462z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M443.077,140.308H324.923c-6.798,0-12.308-5.51-12.308-12.308c0-6.798,5.51-12.308,12.308-12.308
		h118.154c6.798,0,12.308,5.51,12.308,12.308C455.385,134.798,449.874,140.308,443.077,140.308z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M187.077,115.692h-46.769V68.923c0-6.798-5.51-12.308-12.308-12.308
		c-6.798,0-12.308,5.51-12.308,12.308v46.769H68.923c-6.798,0-12.308,5.51-12.308,12.308c0,6.798,5.51,12.308,12.308,12.308h46.769
		v46.769c0,6.798,5.51,12.308,12.308,12.308c6.798,0,12.308-5.51,12.308-12.308v-46.769h46.769c6.798,0,12.308-5.51,12.308-12.308
		C199.385,121.202,193.874,115.692,187.077,115.692z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M145.404,384l40.529-40.529c4.807-4.807,4.807-12.596,0-17.404s-12.596-4.807-17.404,0L128,366.596
		l-40.529-40.529c-4.807-4.807-12.596-4.807-17.404,0c-4.809,4.807-4.807,12.596,0,17.404L110.596,384l-40.529,40.529
		c-4.807,4.807-4.807,12.596,0,17.404c2.404,2.404,5.548,3.606,8.702,3.606s6.298-1.202,8.702-3.606l40.529-40.529l40.529,40.529
		c2.404,2.404,5.548,3.606,8.702,3.606s6.298-1.202,8.702-3.606c4.807-4.807,4.807-12.596,0-17.404L145.404,384z"
      />
    </g>
    <path
      style="fill:#E4EAF8;"
      d="M452.923,512H265.846V275.692c0-5.438,4.409-9.846,9.846-9.846H512v187.077
	C512,485.551,485.551,512,452.923,512z"
    />
    <path
      style="fill:#D5DCED;"
      d="M265.846,482.462V512h187.077c21.854,0,40.89-11.898,51.109-29.538H265.846z"
    />
    <g>
      <path
        style="fill:#7F8499;"
        d="M443.077,366.769H324.923c-6.798,0-12.308-5.51-12.308-12.308s5.51-12.308,12.308-12.308h118.154
		c6.798,0,12.308,5.51,12.308,12.308S449.874,366.769,443.077,366.769z"
      />
      <path
        style="fill:#7F8499;"
        d="M443.077,425.846H324.923c-6.798,0-12.308-5.51-12.308-12.308s5.51-12.308,12.308-12.308h118.154
		c6.798,0,12.308,5.51,12.308,12.308S449.874,425.846,443.077,425.846z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
